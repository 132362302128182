import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';

function Navi() {
  return (
    <Navbar className="bg-body-tertiary" data-bs-theme="dark">
      <Container>
        <Navbar.Brand>RYG</Navbar.Brand>
        <Navbar.Toggle/>
      </Container>
    </Navbar>
  );
}

export default Navi;