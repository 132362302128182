import logo from './logo.svg';
import './App.css';
import Navi from "./Components/Navi";
import {Container} from "react-bootstrap";
import Style from './App.css';
import {useEffect} from "react";
import Landing from "./Components/Landing";


function App() {

  useEffect(() => {
    onLoad();
  }, []);


  async function onLoad() {
    document.body.style.backgroundImage = "linear-gradient(45deg, #de620e, #75375a, #3e257d)"
    document.body.style.color = "#0D303B"
    document.body.style.backgroundAttachment = "fixed"
  }

  return (
    <div>
        <Navi/>
        <div className={Style.Container}>
          <Container data-bs-theme="dark">
            <Landing/>
          </Container>
        </div>
    </div>
  );
}

export default App;
