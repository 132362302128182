import React, {useEffect, useState} from 'react';
import Jumbotron from "./Bricks/Jumbotron";
import {Col, Row, Tab, Tabs} from "react-bootstrap";
import Panel from "./Bricks/Panel";


export default function Landing() {
  return (
    <div>
      <Jumbotron title={"Ciao!"}>
        <p>Benvenutə sul sito istituzionale della RYG! Qui puoi trovare informazioni sul gruppo, la sua struttura e il
          regolamento.</p>
      </Jumbotron>
      <Tabs
        defaultActiveKey="gruppo"
        id="fill-tab-example"
        className="mb-3"
        fill
      >
        <Tab eventKey="gruppo" title="Gruppo">
          <Panel>
            <h2>Che cos'è la RYG?</h2>
            <p>
              La RYG è un gruppo di giocatori (video e non) nato nel 2010, che consente alle persone di conoscersi meglio
              tra di loro e condividere le proprie passioni, utilizzando come principale strumento per facilitare la
              socializzazione il gaming. Per quanto i videogiochi siano ancora un elemento centrale nella RYG, nel corso
              del tempo si sono venute a formare sottocommunity interne, dedicate a giochi di ruolo, stampa 3D, programmazione
              e molto altro.
            </p>
            <h2>Come faccio a partecipare alle attività del gruppo?</h2>
            <p>
              La RYG è un gruppo aperto, ovvero chiunque può venire a salutare e prendere parte al divertimento nel ruolo di ospite.
              Il canale principale con cui prendere parte alla vita del gruppo è il server <a href="http://discord.ryg.one">Discord</a>.
            </p>
            <h2>Qual'è la differenza tra ospite e membro?</h2>
            <p>Rispetto ad un ospite, il membro ha accesso a tutti i servizi offerti dall'infrastruttura della RYG,
              può entrare all'interno del gruppo Telegram (e di tutti i sottogruppi ad esso legati) e partecipare alle votazioni.</p>
            <h2>Come divento membro?</h2>
            <p>Per diventare membro, è necessario che un minimo del 10% dei membri RYG appoggino il tuo ingresso. Il modo migliore, quindi, è prendere parte alle attività su Discord come ospite. La richiesta verrà esaminata dal coordinatore del gruppo.</p>
          </Panel>
        </Tab>
        <Tab eventKey="struttura" title="Struttura">
          <Panel>
            La RYG è composta, oltre che dai membri, dalle seguenti figure:

            <Row>
              <Col>
                <Panel>
                  <h2>Coordinatore</h2>
                  <p>Il coordinatore svolge una funzione di controllo sulle altre figure facenti parte della struttura,
                    coordinandole ed ausiliandole nei loro ruoli. </p><p>Il coordinatore può venire contattato per
                  qualsiasi cosa
                  riguardi il gruppo che non sia di competenza delle cariche sue sottoposte.</p>
                  <p>E' una carica non soggetta a scadenza, in caso di dimissioni del coordinatore ne viene nominato
                    uno nuovo tramite elezione. Il coordinatore attuale è <a href="http://t.me/EvilBalu">Nemesis</a>.
                  </p>
                </Panel>
              </Col>
              <Col>
                <Panel>
                  <h2>Tecnico</h2>
                  <p>Il tecnico è una figura di supporto. Gestisce l'infrastruttura della RYG, in termini di gruppi e
                    servizi informativi.</p>
                  <p>Per ricoprire tale carica, il membro autocandidato deve avere competenze in ambito
                    informatico/sistemistico (riconosciute o da autodidatta).</p>
                  <p>E' una carica non soggetta a scadenza ed è un incarico affidato dal coordinatore in seguito alla
                    candidatura volontaria di un membro.</p>
                </Panel>
              </Col>
              <Col>
                <Panel>
                  <h2>Moderatore</h2>
                  <p>Il moderatore applica il regolamento, applicando restrizioni (temporanee o permanenti) agli utenti
                    che non lo rispettano.</p>
                  <p>Le sanzioni possono essere ammonizioni, limitazioni nella condivisione dei contenuti, silence e in
                    casi estremi, rimozione dell'utente dal gruppo.</p>
                  <p>E' una carica soggetta a scadenza annuale, gli incarichi vengono conferiti tramite elezione e
                    approvati dal coordinatore. La
                    candidatura è su base volontaria.</p>
                </Panel>
              </Col>
              <Col>
                <Panel>
                  <h2>Organizzatore</h2>
                  <p>L'organizzatore gestisce le attività di comunità della RYG, come eventi, server di gioco o
                    simili.</p>
                  <p>Gli eventi gestiti dall'organizzatore possono essere sia farina del suo sacco, sia suggeriti dal
                    gruppo stesso tramite l'apposita sezione su Discord.</p>
                  <p>E' una carica soggetta a scadenza annuale, gli incarichi vengono conferiti tramite elezione. La
                    candidatura è su base volontaria, può venire concesso temporaneamente.</p>
                </Panel>
              </Col>
            </Row>
            <p>
              L'organizzazione eventi, per quanto responsabilità degli organizzatori, è comunque anche concessa ai
              membri.
              Questi possono proporre attività sullo spazio apposito del gruppo Discord, e qualora nessun organizzatore
              sia disponibile
              per organizzare un evento giudicato interessante può venire - temporaneamente - nominato un nuovo
              organizzatore.
            </p>
          </Panel>
        </Tab>
        <Tab eventKey="regolamento" title="Regolamento">
          <Panel>
            <h2>Regolamento</h2>
            <i>Anche noto come "La Legge del Funny"</i>
            <br/>
            <p>Perchè la RYG funzioni bene e sia uno spazio positivo per tutti, è necessario che tutti coloro che vi
              partecipano siano in sintonia su come bisogna comportarvicisi!
              Questo RYGolamento ha un duplice scopo:</p>
            <ul>
              <li> essere una guida per i partecipanti riguardo il comportamento da avere nel gruppo</li>
              <li> essere uno strumento utilizzabile dai moderatori per la risoluzione delle dispute</li>
            </ul>
            <p>
              In estrema sintesi, il regolamento si riassume nei seguenti punti:
            </p>
            <ol>
              <li>Non mancare di rispetto all'identità degli altri</li>
              <li>Non assumere che le cose siano come appaiono a prima vista</li>
              <li>Non attaccare verbalmente, offendere, discriminare o avere un atteggiamento tossico verso il
                prossimo
              </li>
              <li>Non inviare spoiler non marcati</li>
              <li>Non inviare oscenità</li>
              <li>Non spammare</li>
              <li>Non infrangere la privacy altrui</li>
              <li>Non importunare in privato chi non vuole esserlo</li>
            </ol>
            <p>Nel momento in cui si entra a far parte del gruppo, il regolamento viene accettato implicitamente. Il
              regolamento non tollera l'intolleranza.</p>
            <p>Se vuoi leggere la versione estesa (cosa consigliata), la puoi trovare a questo <a
              href="https://gist.github.com/Steffo99/f436a935e0e53ca0be81b98384e2176f#file-1-rygolamento-md">link</a>.
            </p>
          </Panel>
        </Tab>
      </Tabs>
    </div>

  );
}